(function($) {
var Sage = {
	'common': {
		init: function() {},
		finalize: function() {

			// Header offset
			$(window).scroll(function() {
				var scroll = $(window).scrollTop();
				var os = $('header').offset().top;
				var ht = $('header').outerHeight();
				
				if(scroll > os + ht){
					$('.menu').addClass('menu--white');
				} else {
					$('.menu').removeClass('menu--white');
				}
			});

			// Headroom
			$('.menu').headroom();

			// Menu Mobile
			$('.menu-mobile').click(function(){
				if($(this).hasClass('active')){
					$(this).removeClass('active');
					$(this).next().slideUp();
				} else {
					$(this).addClass('active');
					$(this).next().slideDown();
				}
			});

			// Meet the team slider
			$('.meet-slider').slick({
				arrows: false,
				mobileFirst: true,
				centerMode: true,
				autoplay: true,
				variableWidth: true,
				slidesToShow: 1,
				initialSlide: 5,
			});

			// Slider
			$('.slider-large').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				fade: true,
				asNavFor: '.slider-small'
			});
			$('.slider-small').slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				asNavFor: '.slider-large',
				focusOnSelect: true,
				autoplay: true,
			});

			// Set Shop Cookie
			if(!Cookies.get('products') || $('.product-wipe').length){
				var productArray = [];
				Cookies.set('products', JSON.stringify(productArray));
			}

			// Determan Product
			var $name = $('.product-button').data('name');
			tempArray = JSON.parse(Cookies.get('products'));
			data = $.grep(tempArray, function (e) {
				if (e.name === $name) {
					console.log('t'); 
					$('.product-button')
						.removeClass('button--tick')
						.addClass('button--trash button--outline added')
						.html('Verwijder uit winkelwagen');
					return true;
				}
			});	

			// Product Button Roulette
			$('.product-button').click(function(){
				if($(this).hasClass('added')){
					$name = $(this).data('name');

					tempArray = JSON.parse(Cookies.get('products'));
					data = $.grep(tempArray, function (e) {
						return e.name === $name;
					});
					output = data[0].name;
					tempArray.splice( $.inArray(output, tempArray), 1 );
					Cookies.set('products', JSON.stringify(tempArray));
					console.log(tempArray);

					$(this)
						.removeClass('button--outline button--tick button--trash added')
						.addClass('button--shop')
						.html('Toevoegen aan winkelwagen');
				} else{
					$name = $(this).data('name');
					$price = $(this).data('price');
					$number = $('#product-number').val();

					tempArray = JSON.parse(Cookies.get('products'));
					productItem ={
						"name":$name,
						"price":$price,
						"number":$number
					};
					tempArray.push(productItem);
					Cookies.set('products', JSON.stringify(tempArray));
					console.log(tempArray);

					$(this)
						.addClass('button--outline button--tick added')
						.html('Toegevoegd aan winkelwagen')
						.delay(2000)
						.queue(function(nxt) {
							$(this)
								.removeClass('button--tick')
								.addClass('button--trash')
								.html('Verwijder uit winkelwagen');
							nxt();
						});
				}
			});

			console.log(tempArray);

			// Shopcart Print List
			if($('.shopping-list').length){
				var endArray = JSON.parse(Cookies.get('products'));
				var outputArray = [];
				$(endArray).each(function(i, obj) {
					$(obj).each(function(i, obj) {
						outputArray.push('- <strong>'+obj.number+'x</strong> '+obj.name+' <strong>&euro; '+obj.price+'</strong><br>');
					});
				});
				if(endArray.length){
					$('.shopping-list').html(outputArray);
					$('#vfb-field-25').val(outputArray);
				}
			}
		}
	}
};

// ----------------------------------------------------------------------------
var UTIL = {
fire: function(func, funcname, args) {
	var fire;
	var namespace = Sage;
	funcname = (funcname === undefined) ? 'init' : funcname;
	fire = func !== '';
	fire = fire && namespace[func];
	fire = fire && typeof namespace[func][funcname] === 'function';
	if (fire) {
		namespace[func][funcname](args);
	}
},
loadEvents: function() {
	UTIL.fire('common');
	$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
		UTIL.fire(classnm);
		UTIL.fire(classnm, 'finalize');
	});
	UTIL.fire('common', 'finalize');
	}
};
jQuery(document).ready(UTIL.loadEvents);
})(jQuery);